import React from "react"
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const AccountServicesPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Account Services" />
    <h1 style={styles.header}>Account Services</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Relax and Enjoy Your Financial Journey With Us</h3>
        <p>
          We believe we can make your financial journey most successful when we get to know you and your goals and can make 
          timely informed decisions based on that knowledge. Our fee-based advisory offers only discretionary accounts, 
          which are sometimes known as managed accounts.
        </p>
      </div>
        <div className="beach-couple-background">
          <div className="img--inner-content">
            <h5>Get Proactive Wealth Management with a Discretionary Account</h5>
            <Link to="/contact">
              <Button className="main-btn">Contact Us</Button>
            </Link>
          </div>
        </div>
      <div style={styles.body}>
        <p>
          <span style={{fontWeight: 600}}>What is a discretionary account?</span><br/>
          Simply, you allow us to buy and sell securities for your benefit. This allows us to be proactive and 
          positioned to move on a moment’s notice as we manage your wealth. At the start of our relationship, you sign a 
          discretionary disclosure to ensure your consent. It’s that easy!
        </p>
        <p>
          <span style={{fontWeight: 600}}>What is a discretionary account?</span><br/>
          We believe discretionary accounts are one of the most valuable tools available to you as an investor. Why? You get:
        </p>
        <ul>
          <li>
            Professional portfolio management that is truly based on your individual investment goals, risk, lifestyle, and tax position.
          </li>
          <li>
            Less time-consuming responsibility for the day-to-day management of your portfolio — which means you have more time to do the things you enjoy
          </li>
          <li>
          Timely and immediate access to investment opportunities.
          </li>
          <li>
            Total access to and ownership of your investments at all times, even though your investments are managed by a professional.
          </li>
          <li>
            Highly personalized financial management that can make a difference to you — and your loved ones — for generations to come.
          </li>
        </ul>
        To learn more about discretionary accounts, please <a href="/contact">contact us</a>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default AccountServicesPage
